import {
  BrowserCacheLocation,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ReactNode, useEffect, useRef, useState } from 'react';

import config from 'config';

import { isNative } from 'utils/capacitor.utils';

import { Routes } from 'constants/routes.constants';

import { Splash } from 'components/@splash';

import IdentityConsumer from './IdentityConsumer';

interface Props {
  children: ReactNode;
}

function getClientId() {
  if (Capacitor.isNativePlatform()) {
    return config.IDENTITY.client_ids.capacitor;
  }
  return config.IDENTITY.client_ids.web;
}

const IdentityGate = ({ children }: Props) => {
  const [pca, setPca] = useState<IPublicClientApplication>();
  const [scopes, setScopes] = useState<Array<string>>([]);
  const hasStarted = useRef(false);
  const platform = Capacitor.getPlatform();

  const getAppIdentifier = async () => {
    if (!isNative) return null;
    return await App.getInfo();
  };

  const getRedirectUri = (platform: string, isBeta: boolean) => {
    if (platform === 'android') {
      if (isBeta) {
        return config.REDIRECT_URIS?.android.beta;
      } else {
        return config.REDIRECT_URIS?.android.release;
      }
    } else if (platform === 'ios') {
      if (isBeta) {
        return config.REDIRECT_URIS?.ios.beta;
      } else {
        return config.REDIRECT_URIS?.ios.release;
      }
    }
    return window.location.origin;
  };

  const getPostLogoutRedirectUri = (platform: string, isBeta: boolean) => {
    if (platform === 'android') {
      if (isBeta) {
        return config.REDIRECT_URIS?.android.beta;
      } else {
        return config.REDIRECT_URIS?.android.release;
      }
    } else if (platform === 'ios') {
      if (isBeta) {
        return config.REDIRECT_URIS?.ios.beta;
      } else {
        return config.REDIRECT_URIS?.ios.release;
      }
    }
    return Routes.News;
  };

  useEffect(() => {
    if (hasStarted.current || !!pca || !config.IDENTITY.issuer_url) return;
    const init = async () => {
      hasStarted.current = true;
      const authorityUrl = config.IDENTITY.issuer_url.replace('v2.0', '');
      setScopes(config.IDENTITY.scopes);

      const platform = Capacitor.getPlatform();

      const redirectUri = getAppIdentifier().then((appIdentifier) => {
        return appIdentifier;
      });

      const appIdentifier = (await redirectUri)?.id ?? '';

      const client = await PublicClientApplication.createPublicClientApplication({
        auth: {
          clientId: getClientId(),
          authority: authorityUrl,
          knownAuthorities: [new URL(authorityUrl).hostname],
          redirectUri: getRedirectUri(platform, appIdentifier.includes('beta')) ?? '',
          postLogoutRedirectUri:
            getPostLogoutRedirectUri(platform, appIdentifier.includes('beta')) ?? '',
        },
        system: {
          // This is needed to allow the login to work in an iframe
          // Attempt to fix sentry issue, github link: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2531
          //allowRedirectInIframe: true,
          allowNativeBroker: true,
          // debug the identity service, we can enable the logger
          // loggerOptions: {
          //   loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
          //     if (containsPii) {
          //       return;
          //     }
          //     switch (level) {
          //       case LogLevel.Error:
          //         console.error(message);
          //         return;
          //       case LogLevel.Info:
          //         console.info(message);
          //         return;
          //       case LogLevel.Verbose:
          //         console.info(message);
          //         return;
          //       case LogLevel.Warning:
          //         console.warn(message);
          //         return;
          //     }
          //   },
          //   piiLoggingEnabled: true,
          // },
        },
        cache: { cacheLocation: BrowserCacheLocation.LocalStorage },
      });

      await client.initialize();
      setPca(client);
    };

    init();
  }, [pca, platform]);

  if (!pca) return <Splash isLoading />;

  return (
    <MsalProvider instance={pca}>
      <IdentityConsumer scopes={scopes}>{children}</IdentityConsumer>
    </MsalProvider>
  );
};

export default IdentityGate;
