import config from 'config';

import RestApiService from './api';
import { createManager } from './api/axios';

export let apiService: RestApiService;

// services are initialised after the tenant config is loaded, so all correct tenant config values are used
export const initServices = () => {
  apiService = new RestApiService({
    api: createManager({ baseURL: config.API_BASE }),
  });
};
