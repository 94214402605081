import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { AppConfigurationResponse } from 'types/configuration.types';

const fetchEnabledModules = async () => {
  const { data: response } = await apiService.getEnabledModules();
  return response;
};

export const useEnabledModules = (
  config: UseCommonQueryOptions<
    AppConfigurationResponse,
    ReturnType<typeof QueryKeys.configuration.enabledModules>
  > = {},
) => {
  const { data, isLoading, isSuccess, isError, error } = useQuery({
    queryKey: QueryKeys.configuration.enabledModules(),
    queryFn: () => fetchEnabledModules(),
    ...config,
    staleTime: config?.staleTime ?? Duration.ONE_HOUR,
  });

  return {
    data: data?.enabledModules,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
