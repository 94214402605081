import { useSelector } from 'react-redux';

import { QueryKeys, useUser } from 'queries';
import { UseCommonQueryOptions } from 'queries/types';
import { getCurrentUserId } from 'store/user/user.selectors';
import { User } from 'types/user.types';

export const useCurrentUser = (
  config: UseCommonQueryOptions<User, ReturnType<typeof QueryKeys.users.byId>> = {},
) => {
  const userId = useSelector(getCurrentUserId);

  const { data, ...rest } = useUser(userId, {
    ...config,
    enabled: !!userId,
  });

  return {
    user: data,
    userId: data?.spencerUuid ?? '',
    serviceId: data?.serviceUuid ?? '',
    ...rest,
  };
};
