import { Components, Theme } from '@mui/material';

export const MuiFormLabel: Components<Theme>['MuiFormLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: '16px',
      color: theme.palette.neutral01[600],

      '&.Mui-focused, &.MuiFormLabel-filled': {
        transform: 'translate(12px, 8px) scale(0.7)',
      },
    }),
  },
};
