import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { QueryKeys, useCurrentUser } from 'queries';
import { UseCommonInfiniteQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { useSelectedLocale } from 'services/i18n';
import { Collection, Href, Pagination } from 'types/api.types';

const fetchNewsTopics = async (userId: string, params: Pagination) => {
  const { data: response } = await apiService.getNewsTopics(userId, params);
  return response;
};

export const useInfiniteNewsTopics = (
  config?: UseCommonInfiniteQueryOptions<
    Collection<Array<Href>>,
    ReturnType<typeof QueryKeys.topics.all>
  >,
) => {
  const { locale } = useSelectedLocale();
  const { userId } = useCurrentUser();
  const [isPaginationLoading, setIsPaginationloading] = useState(true);

  const {
    fetchNextPage,
    error,
    hasNextPage,
    isFetchingNextPage,
    data,
    isSuccess,
    isInitialLoading,
  } = useInfiniteQuery({
    queryKey: QueryKeys.topics.all(locale, userId),
    queryFn: ({ pageParam = 1 }) => {
      return fetchNewsTopics(userId, { p: pageParam });
    },
    getNextPageParam: (lastPage) => {
      const next = lastPage?.links?.next;
      if (!next) return undefined;

      const nextPage = new URL(next).searchParams.get('p');
      return nextPage ?? undefined;
    },
    ...config,
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    } else if (!hasNextPage && !isInitialLoading) {
      setIsPaginationloading(false);
    }
  }, [hasNextPage, fetchNextPage, data, isInitialLoading]);

  return {
    data: data?.pages.flatMap((page) => page.data ?? []) ?? [],
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading: isPaginationLoading || isInitialLoading,
    isInitialLoading,
    isSuccess,
    error,
  };
};
