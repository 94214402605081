import { Components, Theme } from '@mui/material';

import Icon from 'components/@icons';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  defaultProps: {
    iconMapping: {
      error: <Icon name="IcoError" size={40} />,
      info: <Icon name="IcoInfo" size={40} />,
    },
  },
  styleOverrides: {
    root: {
      alignItems: 'center',
    },
    standardInfo: ({ theme }) => ({
      backgroundColor: theme.palette.brand.main[10],
      color: theme.palette.brand.main[100],
      '& .MuiAlert-icon': {
        color: theme.palette.brand.main[100],
      },
    }),
    standard: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius * 2,
    }),
    standardError: ({ theme }) => ({
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
    }),
    message: ({ theme }) => ({
      ...theme.typography.overline,
      textTransform: 'none',
    }),
  },
};
