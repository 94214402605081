export enum UserRole {
  User = 'user',
  Admin = 'admin',
}

export enum MeasurementEntity {
  News = 'news',
  Video = 'video',
  Search = 'search',
  Document = 'document',
  Link = 'link',
  Module = 'module',
  Chat = 'chat',
}

export enum MeasurementEventName {
  Show = 'show',
  Open = 'open',
  Hide = 'hide',
  Mute = 'mute',
  Unmute = 'unmute',
  Pause = 'pause',
  End = 'end',
  Play = 'play',
  Ping = 'ping',
  Scroll = 'scroll',
  Query = 'query',
  Send = 'send',
  LinkedContentShow = 'linked_content_show',
  ResultClicked = 'result_clicked',
  Fullscreen = 'fullscreen',
  Interaction = 'interaction',
  ReactionAdded = 'reaction_added',
  ReactionRemoved = 'reaction_removed',
  CommentAdded = 'comment_added',
  CommentEdited = 'comment_edited',
  CommentRemoved = 'comment_removed',
  Impression = 'impression',
}

export type MeasurementsEventPayload = {
  entity: MeasurementEntity;
  eventName: MeasurementEventName;
  data: object;
};
