import { Box, Divider, Drawer, MenuList, Stack, styled } from '@mui/material';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { hideScrollbar } from 'utils/styles.utils';

import { useTenantConfig } from 'queries';
import { TenantUpdateSidebar } from 'services/tenant/TenantUpdateProvider';

import { TenantLogo } from 'components/@common';
import Icon from 'components/@icons';
import { BottomNav } from 'components/@navigations/BottomNav';
import { useNavItems, useScrollBorders } from 'components/@navigations/hooks';

import NavigationImage from './NavigationImage';
import NavItem from './NavItem';

const AnimatedDivider = styled(Divider, { shouldForwardProp: (prop) => prop !== 'show' })<{
  show: boolean;
}>(({ theme, show }) => ({
  borderColor: theme.palette.neutral01[400],
  opacity: show ? 1 : 0,
  transition: theme.transitions.create('opacity'),
}));

const DrawerNavigation = () => {
  const intl = useIntl();
  const navItems = useNavItems();
  const scrollContainer = useRef<HTMLDivElement>(null);
  const showScrollDividers = useScrollBorders(scrollContainer);

  const { data: tenant } = useTenantConfig();

  const zoomFactor = tenant?.configuration.zoom;

  return (
    <div className="no-print">
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: (theme) => ({
            pb: 3,
            width: theme.mixins.sideBar.width,
            position: 'fixed',
            backgroundColor: theme.palette.brand.navigation.main[100],
            borderRight: `1px solid ${theme.palette.neutral01[400]}`,
            transform: zoomFactor ? `scale(${zoomFactor})` : 'none',
            transformOrigin: zoomFactor ? 'left top' : 'none',
            height: zoomFactor ? `${100 / Number(zoomFactor)}%` : '100%',
          }),
        }}
      >
        <Stack flex="1" overflow="hidden">
          <Box pt={2.5} px={2} pb="30px">
            <TenantLogo />
          </Box>

          <AnimatedDivider show={showScrollDividers.top} />

          <Box overflow="scroll" sx={{ ...hideScrollbar }} ref={scrollContainer}>
            <MenuList
              sx={{ px: 2, mt: '6px', mb: 1, display: 'flex', flexDirection: 'column', gap: 1 }}
            >
              {navItems.map((item, index) =>
                'Component' in item ? (
                  <item.Component key={index} />
                ) : (
                  <NavItem
                    key={index}
                    to={item.to}
                    target={item.target}
                    label={item.fetched ? item.label : intl.formatMessage({ id: item.label })}
                    startIcon={
                      !item.fetched && item.startIcon ? (
                        <Icon name={item.startIcon} />
                      ) : item.startImage ? (
                        <NavigationImage startImage={item.startImage} />
                      ) : undefined
                    }
                  />
                ),
              )}
            </MenuList>
          </Box>

          <Stack mt="auto">
            <AnimatedDivider show={showScrollDividers.bottom} />

            <Stack px={2} pt={1} gap={1}>
              <TenantUpdateSidebar />
              <BottomNav />
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
      <Box
        sx={(theme) => ({
          width: zoomFactor
            ? `calc(${theme.mixins.sideBar.width} * ${zoomFactor})`
            : `${theme.mixins.sideBar.width}`,
        })}
      />
    </div>
  );
};

export default DrawerNavigation;
