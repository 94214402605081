import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useSelectedLocale } from 'services/i18n';
import { useNotifications } from 'services/snackbar';
import { Collection, Href } from 'types/api.types';

interface Params {
  userId: string;
  topicId: number;
  href: string;
}

const createUserTopic = async ({ userId, topicId }: Params) =>
  apiService.createUserTopic(userId, topicId);

export const useCreateUserTopic = () => {
  const { error } = useNotifications();
  const intl = useIntl();
  const { locale } = useSelectedLocale();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: createUserTopic,
    onMutate: async (params) => {
      await queryClient.cancelQueries({ queryKey: QueryKeys.topics.user(params.userId, locale) });
      const previousTopics = queryClient.getQueryData<InfiniteData<Collection<Array<Href>>>>(
        QueryKeys.topics.user(params.userId, locale),
      );

      if (!previousTopics) return;

      if (previousTopics.pages[previousTopics.pages.length - 1].data?.length === 10) {
        previousTopics.pages.push({ data: [{ href: params.href }] });
      } else {
        previousTopics.pages[previousTopics.pages.length - 1].data?.push({
          href: params.href,
        });
      }

      const newTopics: InfiniteData<Collection<Array<Href>>> = {
        ...previousTopics,
        pages: previousTopics.pages,
      };

      queryClient.setQueryData(QueryKeys.topics.user(params.userId, locale), newTopics);

      return { previousTopics };
    },
    onError: (_error, { userId }, context) => {
      error({
        title: intl.formatMessage({ id: 'core_general_alert_error_title' }),
        message: intl.formatMessage({ id: 'core_general_failed' }),
      });
      queryClient.setQueryData(QueryKeys.topics.allUserTopics(userId), context?.previousTopics);
    },
  });

  return { createUserTopic: mutate, isLoading };
};
