import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';

import { notEmpty } from 'utils/typescript.utils';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { useSelectedLocale } from 'services/i18n';

type Options<T> = {
  localized?: boolean;
  config?: UseCommonQueryOptions<T, ReturnType<typeof QueryKeys.href>>;
};

const fetchHref = async (href: string) => {
  const { data: response } = await apiService.api(href);
  return response;
};

/**
 * Generic query for fetching hrefs in parallel
 */
export const useHrefs = <T>(
  hrefs: Array<string>,
  { config = {}, localized = true }: Options<T> = { localized: true, config: {} },
) => {
  const { locale } = useSelectedLocale();

  const queries = useQueries({
    queries: hrefs?.map((href) => ({
      queryKey: QueryKeys.href(href ?? '', localized ? locale : undefined),
      queryFn: () => fetchHref(href ?? ''),
      staleTime: Duration.FIFTEEN_MIN,
      enabled: !!href,
      ...config,
    })),
  });

  const isLoading = useMemo(
    () => !!hrefs.length && queries.some((item) => item.isLoading),
    [queries, hrefs],
  );

  const isFetching = useMemo(
    () => !!hrefs.length && queries.some((item) => item.isFetching),
    [queries, hrefs],
  );

  return {
    data: queries?.flatMap((item) => item.data).filter(notEmpty),
    isLoading,
    isFetching,
  };
};
