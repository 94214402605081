import { Pagination } from 'types/api.types';
import { TopicsResponse } from 'types/topics.types';

import RestApiService from '.';

export default {
  getNewsTopics(this: RestApiService, userId: string, params: Pagination) {
    return this.api.get<TopicsResponse>(`/api/users/${userId}/news/topics`, {
      params,
    });
  },
  getUserTopics(this: RestApiService, userId: string, params: Pagination) {
    return this.api.get<TopicsResponse>(`/api/users/${userId}/news/topic-subscriptions`, {
      params,
    });
  },
  deleteUserTopic(this: RestApiService, userId: string, topicId: number) {
    return this.api.delete(`/api/users/${userId}/news/topic-subscriptions/${topicId}`);
  },
  createUserTopic(this: RestApiService, userId: string, topicId: number) {
    return this.api.post(`/api/users/${userId}/news/topic-subscriptions`, {
      topic: topicId,
    });
  },
};
