import { Modules } from 'components/@navigations/config';

export enum UserRoles {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER_ADMIN = 'ROLE_USER_ADMIN',
  ROLE_ADMIN_USER_ADMIN = 'ROLE_ADMIN_USER_ADMIN',
  ROLE_USER_INVITOR = 'ROLE_USER_INVITOR',
  ROLE_ADMIN_USER_ROLES_CHANGER = 'ROLE_ADMIN_USER_ROLES_CHANGER',
  ROLE_SEGMENTATION_USER_LIST_ADMIN = 'ROLE_SEGMENTATION_USER_LIST_ADMIN',
  ROLE_MY_DAY_ADMIN = 'ROLE_MY_DAY_ADMIN',
  ROLE_MY_DAY_PROJECT_ADMIN = 'ROLE_MY_DAY_PROJECT_ADMIN',
  ROLE_MY_DAY_CATEGORY_ADMIN = 'ROLE_MY_DAY_CATEGORY_ADMIN',
  ROLE_NEWS_SOURCE_READER = 'ROLE_NEWS_SOURCE_READER',
  ROLE_NEWS_SOURCE_ADMIN = 'ROLE_NEWS_SOURCE_ADMIN',
  ROLE_NEWS_TOPIC_ADMIN = 'ROLE_NEWS_TOPIC_ADMIN',
  ROLE_NEWS_ITEM_ADMIN = 'ROLE_NEWS_ITEM_ADMIN',
  ROLE_CONTENT_CREATOR = 'ROLE_CONTENT_CREATOR',
  ROLE_MESSAGING_COMPANY_CHANNEL_ADMIN = 'ROLE_MESSAGING_COMPANY_CHANNEL_ADMIN',
  ROLE_EMERGENCY_ADMIN = 'ROLE_EMERGENCY_ADMIN',
  ROLE_SITE_ADMIN = 'ROLE_SITE_ADMIN',
  ROLE_ENRICHMENT_ADMIN = 'ROLE_ENRICHMENT_ADMIN',
  ROLE_LINK_LIBRARY_LINK_ADMIN = 'ROLE_LINK_LIBRARY_LINK_ADMIN',
  ROLE_DOCUMENT_LIBRARY_ADMIN = 'ROLE_DOCUMENT_LIBRARY_ADMIN',
  ROLE_SURVEY_ADMIN = 'ROLE_SURVEY_ADMIN',
}

export type AppConfigurationResponse = {
  enabledModules: Array<Modules>;
};

export type MeResponse = {
  restrictedModules: Array<Modules>;
  roles: Array<UserRoles>;
};
