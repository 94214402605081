import { Capacitor } from '@capacitor/core';
import {
  captureException as captureNativeException,
  setExtra as setNativeExtra,
  setTag as setNativeTag,
  setUser as setNativeUser,
} from '@sentry/capacitor';
import {
  captureException as captureWebException,
  setExtra as setWebExtra,
  setTag as setWebTag,
  setUser as setWebUser,
} from '@sentry/react';

const isNativePlatform = Capacitor.isNativePlatform();

export const captureException = isNativePlatform ? captureNativeException : captureWebException;
export const setTag = isNativePlatform ? setNativeTag : setWebTag;
export const setExtra = isNativePlatform ? setNativeExtra : setWebExtra;
export const setUser = isNativePlatform ? setNativeUser : setWebUser;
