import { Box } from '@mui/material';

import { ReactionKind } from 'types/news.types';

import Icon from 'components/@icons';

type KeyValueType = {
  [key: string]: {
    iconName:
      | 'IcoReactionLove'
      | 'IcoReactionLike'
      | 'IcoReactionCurious'
      | 'IcoReactionInspired'
      | 'IcoReactionCelebrate';
    color: string;
  };
};

const keyValue: KeyValueType = {
  [ReactionKind.Love]: { iconName: 'IcoReactionLove', color: 'reaction.love' },
  [ReactionKind.Like]: { iconName: 'IcoReactionLike', color: 'reaction.like' },
  [ReactionKind.Curious]: { iconName: 'IcoReactionCurious', color: 'reaction.curious' },
  [ReactionKind.Inspired]: { iconName: 'IcoReactionInspired', color: 'reaction.inspired' },
  [ReactionKind.Celebrate]: { iconName: 'IcoReactionCelebrate', color: 'reaction.celebrate' },
};

interface Props {
  kind: ReactionKind;
  size: number;
}
const Reaction = ({ kind, size }: Props) => {
  const reaction = keyValue[kind];
  if (!reaction) return null;

  return (
    <Box bgcolor={reaction.color} borderRadius="50%" display="flex" color="neutral01.100">
      <Icon name={reaction.iconName} size={size} />
    </Box>
  );
};

export default Reaction;
