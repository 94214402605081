import { alpha, Autocomplete, Paper, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import { hideClearIcon } from 'utils/styles.utils';

import { Routes } from 'constants/routes.constants';
import { useMedia } from 'hooks';
import { useTenantConfig } from 'queries';
import { getRecentSearches, getShowRecentSearches } from 'store/search/search.selectors';
import { searchActions } from 'store/search/search.slice';

import { SearchEndAdornment } from 'components/@common';

interface Props {
  onSearchChange?: (searchValue: string) => void;
  initialFocus?: boolean;
}

const SearchBar = ({ onSearchChange, initialFocus = false }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isDesktop } = useMedia();
  const [searchParams] = useSearchParams();
  const searchValueParam = searchParams.get('value');
  const showRecentSearches = useSelector(getShowRecentSearches);
  const [searchValue, setSearchValue] = useState(searchValueParam ?? '');
  const recentSearches = useSelector(getRecentSearches);
  const [isOpen, setIsOpen] = useState(false);
  const textInput = useRef<HTMLInputElement>(null);
  const intl = useIntl();
  const { data: tenant } = useTenantConfig();

  const zoomFactor = tenant?.configuration.zoom;

  useEffect(() => {
    if (initialFocus) {
      textInput?.current?.focus();
    }
  }, [initialFocus]);

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleNavigate = (option?: string) => {
    dispatch(searchActions.setShowRecentSearches(false));
    if (option) {
      handleSearchChange(option);
    }

    if (option || searchValue) {
      dispatch(searchActions.addRecentSearch(option ?? searchValue));
      navigate({
        pathname: Routes.Search,
        search: `?${createSearchParams({ value: option ?? searchValue })}`,
      });
    }
    textInput.current?.blur();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleSearchChange(e.target.value);
    if (!showRecentSearches) dispatch(searchActions.setShowRecentSearches(true));
    onSearchChange?.(e.target.value);
  };

  const handleClear = () => {
    handleSearchChange('');
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleNavigate();
    }
  };

  return (
    <Autocomplete
      freeSolo
      disableClearable
      value={searchValue}
      open={isOpen}
      options={recentSearches}
      onChange={(_e, option) => handleNavigate(option)}
      onInputChange={(e, value) => {
        if (e === null) return;
        if (!recentSearches.some((search) => search.match(value))) {
          setIsOpen(false);
        } else {
          if (!isOpen) setIsOpen(true);
        }
      }}
      onClose={() => setIsOpen(false)}
      sx={{
        maxWidth: 564,
        flexGrow: 1,
        transform: zoomFactor && isDesktop ? `scale(${zoomFactor})` : 'none',
        transformOrigin: zoomFactor && isDesktop ? 'center' : 'none',
      }}
      PaperComponent={({ ...props }) => {
        return (
          <Paper
            {...props}
            sx={{
              transform: zoomFactor && isDesktop ? `scale(${zoomFactor})` : 'none',
              transformOrigin: zoomFactor && isDesktop ? 'top' : 'none',
            }}
          >
            <Typography variant="h6" px={3} pt={3} pb={2}>
              <FormattedMessage id="core_search_recent_searches" />
            </Typography>
            {recentSearches.length ? (
              props.children
            ) : (
              <Typography variant="body2" color="neutral01.600" mx={3} mb={3}>
                <FormattedMessage id="core_search_recent_searches_empty" />
              </Typography>
            )}
          </Paper>
        );
      }}
      componentsProps={{
        paper: {
          sx: (theme) => ({
            mt: { xs: 1.5, md: 1 },
            borderRadius: 4,
            border: { xs: 'none', md: `1px solid ${theme.palette.neutral01[300]}` },
            filter: `drop-shadow(0 2px 10px ${alpha(theme.palette.neutral01[800], 0.12)})`,
            display: { xs: 'none', md: 'block' },
          }),
          //Remove default elevation
          elevation: 0,
        },
      }}
      ListboxProps={{
        sx: {
          maxHeight: '40vh',
        },
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder={intl.formatMessage({ id: 'core_search_placeholder' })}
            type="search"
            size="small"
            fullWidth
            inputRef={textInput}
            sx={(theme) => ({
              '& input': {
                padding: theme.spacing(1.5, 2),
              },
              '& .MuiInputBase-root fieldset': {
                borderRadius: 8,
              },
              ...hideClearIcon(theme),
            })}
            onChange={handleChange}
            onKeyUp={handleKeyPress}
            InputProps={{
              ...params.InputProps,
              endAdornment: <SearchEndAdornment value={searchValue} onClear={handleClear} />,
            }}
          />
        );
      }}
    />
  );
};

export default SearchBar;
