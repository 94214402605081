import { useInfiniteQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import { UseCommonInfiniteQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { useSelectedLocale } from 'services/i18n';
import { Collection, Href } from 'types/api.types';
import { LinkParams } from 'types/links.types';

const fetchLinks = async (params: LinkParams) => {
  const { data: response } = await apiService.getLinks(params);
  return response;
};

export const useInfiniteLinks = (
  params: LinkParams,
  config?: UseCommonInfiniteQueryOptions<
    Collection<Array<Href>>,
    ReturnType<typeof QueryKeys.links.all>
  >,
) => {
  const { locale } = useSelectedLocale();

  const { fetchNextPage, isLoading, hasNextPage, isFetchingNextPage, data } = useInfiniteQuery({
    queryKey: QueryKeys.links.all(locale, params),
    queryFn: ({ pageParam = 1 }) => {
      return fetchLinks({ p: pageParam, ...params });
    },
    getNextPageParam: (lastPage) => {
      const next = lastPage?.links?.next;
      if (!next) return undefined;

      const nextPage = new URL(next).searchParams.get('p');
      return nextPage ?? undefined;
    },
    ...config,
    staleTime: Duration.FOREVER,
  });

  return {
    data: data?.pages.flatMap((page) => page.data),
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading,
  };
};
