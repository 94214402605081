import React, { lazy, Suspense, useMemo, useState } from 'react';

import config from 'config';

import { useMedia } from 'hooks';
import { useTenantConfig } from 'queries';
import { FeatureFlags } from 'types/features.types';

import ZoomScaleContainer from 'components/@layout/Layout/ZoomScaleContainer';
import Privacy from 'components/@onboarding/Privacy';
import SelectLanguage from 'components/@onboarding/SelectLanguage';
import SelectTopics from 'components/@onboarding/SelectTopics';
import { OnboardingProps, OnboardingSteps } from 'components/@onboarding/types';
import { useSubscriptionFormTopics } from 'components/@settings/NewsSubscriptions/hooks';

// We lazy load this chunk because it's only needed for native platforms (reduces bundle size for web)
const SelectNotifications = lazy(
  () => import('components/@onboarding/SelectNotifications/SelectNotifications'),
);

const Onboarding = () => {
  // Prefetch topics
  useSubscriptionFormTopics();
  const { isDesktop } = useMedia();
  const zoomFactor = useTenantConfig()?.data?.configuration?.zoom;

  // memoized instead of outside of the component, because we otherwise get a "Can't access before initialization" error
  const steps: Record<OnboardingSteps, React.FunctionComponent<OnboardingProps>> = useMemo(
    () => ({
      [OnboardingSteps.selectLanguage]: SelectLanguage,
      [OnboardingSteps.privacy]: Privacy,
      [OnboardingSteps.selectTopics]: SelectTopics,
      [OnboardingSteps.selectNotifications]: SelectNotifications,
    }),
    [],
  );

  const skipLanguageStep = config.LOCALES.available.length === 1;
  const skipPrivacyStep = !config.FEATURES[FeatureFlags.ONBOARDING_PRIVACYPOLICY];

  const [activeStep, setActiveStep] = useState(
    skipLanguageStep && skipPrivacyStep
      ? OnboardingSteps.selectTopics
      : skipLanguageStep
        ? OnboardingSteps.privacy
        : OnboardingSteps.selectLanguage,
  );

  const Component = steps[activeStep];

  const Wrapper = isDesktop ? ZoomScaleContainer : React.Fragment;

  return (
    <Wrapper
      sx={
        isDesktop
          ? {
              width: `calc(100svw / ${Number(zoomFactor) > 1 ? zoomFactor : 1})`,
              maxWidth: 'unset !important',
              ...(Number(zoomFactor || 1) < 1 ? { transform: 'unset' } : {}),
              paddingLeft: '0 !important',
            }
          : undefined
      }
    >
      <Suspense>
        <Component
          skipLanguageStep={skipLanguageStep}
          skipPrivacyStep={skipPrivacyStep}
          setActiveStep={setActiveStep}
        />
      </Suspense>
    </Wrapper>
  );
};

export default Onboarding;
