import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { MeResponse } from 'types/configuration.types';

const fetchMe = async () => {
  const { data: response } = await apiService.getMe();
  return response;
};

export const useMe = (
  config: UseCommonQueryOptions<MeResponse, ReturnType<typeof QueryKeys.configuration.me>> = {},
) => {
  const { data, isLoading, isSuccess, isError, error } = useQuery({
    queryKey: QueryKeys.configuration.me(),
    queryFn: () => fetchMe(),
    ...config,
    staleTime: config?.staleTime ?? Duration.ONE_HOUR,
  });

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
