import { Box, styled } from '@mui/material';
import React, { SVGProps } from 'react';

import DefaultIconLookup from './defaults';

export type IconNames = keyof typeof DefaultIconLookup;

export const IconNamesArray = Object.keys(DefaultIconLookup) as Array<IconNames>;

export interface Props extends Omit<SVGProps<SVGSVGElement>, 'ref' | 'rotate'> {
  name: IconNames;
  size?: number;
  rotation?: number;
}

const Icon = ({ name, size = 24, rotation, ...rest }: Props) => {
  const LookUpIcon = DefaultIconLookup[name];
  return (
    <React.Suspense fallback={<Box height={size} width={size} />}>
      {LookUpIcon ? (
        <LookUpIcon
          width={size}
          height={size}
          {...rest}
          style={{
            transition: 'transform 0.2s ease-in-out',
            transform: rotation ? `rotate(${rotation}deg)` : undefined,
            ...rest.style,
          }}
        />
      ) : null}
    </React.Suspense>
  );
};

export default styled(Icon)({});
