/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */
import { useMemo } from 'react';
import { NonIndexRouteObject, RouteObject } from 'react-router-dom';

import { useEnabledRouteModules } from 'utils/module.utils';

import newsPreview from 'pages/news/news-preview';

import { RouteBoundary } from 'components/@boundaries';
import { Layout } from 'components/@layout';
import { useEnabledIframeModules } from 'components/@navigations/hooks';

import bookmarks from './bookmarks';
import chat from './chat';
import documents from './documents';
import forms from './forms';
import Iframe from './iframe';
import links from './links';
import mobileMenu from './mobile-menu';
import news from './news';
import notFound from './not-found';
import organisation from './organisation';
import profile from './profile';
import search from './search';
import settings from './settings';
import sitesDetail from './sites-detail';
import survey from './survey';
import surveyEdit from './survey-edit';

export const rootRoute: NonIndexRouteObject & { children: Array<RouteObject> } = {
  path: '/',
  element: <Layout />,
  errorElement: <RouteBoundary />,
  children: [
    news,
    newsPreview,
    documents,
    organisation,
    forms,
    links,
    settings,
    profile,
    sitesDetail,
    search,
    mobileMenu,
    notFound,
    bookmarks,
    survey,
    surveyEdit,
    Iframe,
    chat,
  ],
};

export const useAllRoutes = () => {
  const { enabledIframeModules } = useEnabledIframeModules();
  const { enabledRouteModules } = useEnabledRouteModules(rootRoute.children);

  const allRoutes = useMemo<NonIndexRouteObject & { children: Array<RouteObject> }>(
    () => ({
      ...rootRoute,
      children: [
        ...enabledRouteModules,
        ...enabledIframeModules.map((route) => ({
          path: route.path,
          lazy: () => import('./iframe-module/Index'),
          id: route.id,
        })),
      ],
    }),
    [enabledIframeModules, enabledRouteModules],
  );

  return allRoutes;
};
