import { Box, Container, SxProps, Theme } from '@mui/material';
import { matchPath, matchRoutes } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useMedia } from 'hooks';
import { useTenantConfig } from 'queries';

import { useAllRoutes } from 'pages/routes';

interface Props {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const ZoomScaleContainer = ({ children, sx }: Props) => {
  const { isDesktop } = useMedia();
  const zoomFactor = useTenantConfig()?.data?.configuration?.zoom;
  const allRoutes = useAllRoutes();
  const matchedRoutes = matchRoutes([allRoutes], location.pathname);
  const exactMatch = matchedRoutes?.find((route) => matchPath(route.pathname, location.pathname));
  const isChatRoute = matchedRoutes?.find((route) => matchPath(route.pathname, Routes.Chat));
  const overrideEnableZoomScaling = exactMatch?.route?.overrideEnableZoomScaling;
  const enableZoomScaling =
    zoomFactor && overrideEnableZoomScaling !== undefined
      ? overrideEnableZoomScaling
      : !!exactMatch?.route?.moduleId;

  if (isChatRoute) {
    return (
      <Box component="main" width="100%" sx={sx}>
        {children}
      </Box>
    );
  }

  return (
    <Container
      component="main"
      sx={
        isDesktop
          ? {
              // @TODO using moduleId here seems very fragile, can we find a better way to do this?
              transform: enableZoomScaling ? `scale(${zoomFactor})` : 'none',
              transformOrigin: enableZoomScaling ? `center top` : 'none',
              // 255px = width of the sidebar, we want to adapt the width of the container so it doesn't render under the sidebar
              width: enableZoomScaling
                ? `calc((100% - calc(255px * ${zoomFactor})) / ${zoomFactor})`
                : 'calc(100% - 255px)',
              ...sx,
            }
          : undefined
      }
    >
      {children}
    </Container>
  );
};

export default ZoomScaleContainer;
