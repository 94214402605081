import { Capacitor } from '@capacitor/core';
import { isEqual, noop } from 'lodash';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import config from 'config';

import { useTenantConfig, useTenantConfigInterval } from 'queries';

interface ContextProps {
  hasUpdates: boolean;
  reload: () => void;
}

const TenantUpdateContext = createContext<ContextProps>({
  hasUpdates: false,
  reload: noop,
});

export const useTenantHasUpdates = () => {
  return useContext(TenantUpdateContext);
};

interface Props {
  children: ReactNode;
}

const TenantUpdateProvider = ({ children }: Props) => {
  const [hasUpdates, setHasUpdates] = useState(false);
  const { data: initialTenant } = useTenantConfig();

  const isMobile = Capacitor.getPlatform() !== 'web'; //TODO THDG: use utils/capacitor.utils (here and in all other spots where Capacitor.getPlatform or .Capacitor.isNativePlatform is used)

  const { data: tenant } = useTenantConfigInterval({
    refetchInterval: hasUpdates || isMobile ? false : false,
    initialData: initialTenant,
  });

  useEffect(() => {
    if (!config.TENANT_CONFIG || hasUpdates || !tenant) return;

    if (!isEqual(tenant, config.TENANT_CONFIG)) {
      setHasUpdates(true);
    }
  }, [hasUpdates, tenant]);

  const reload = useCallback(() => {
    window.location.reload();
  }, []);

  const contextValue = useMemo(() => ({ hasUpdates, reload }), [hasUpdates, reload]);

  return (
    <TenantUpdateContext.Provider value={contextValue}>{children}</TenantUpdateContext.Provider>
  );
};

export default TenantUpdateProvider;
