import { useQuery } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { useSelectedLocale } from 'services/i18n';

type Options<T> = {
  localized?: boolean;
  config?: UseCommonQueryOptions<T, ReturnType<typeof QueryKeys.href>>;
};

const fetchHref = async (href: string) => {
  const { data: response } = await apiService.api(href);
  return response;
};

/**
 * Generic query for fetching hrefs
 */
export const useHref = <T>(
  href = '',
  { config = {}, localized = true }: Options<T> = { localized: true, config: {} },
) => {
  const { locale } = useSelectedLocale();

  return useQuery({
    queryKey: QueryKeys.href(href, localized ? locale : undefined),
    queryFn: () => fetchHref(href),
    staleTime: Duration.FIFTEEN_MIN,
    enabled: !!href,
    ...config,
  });
};
