import { Capacitor } from '@capacitor/core';
import { CssBaseline } from '@mui/material';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { lazy, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { ChatClientProvider } from 'services/chat/ChatClientProvider';
import { IdentityGate } from 'services/identity';
import { IntlProvider } from 'services/intl';
import { MeasurementsProvider } from 'services/measurements';
import LazyMotion from 'services/motion';
import { PrefetchGate } from 'services/prefetcher';
import { persistOptions, queryClient } from 'services/react-query';
import { SnackbarProvider } from 'services/snackbar';
import { TenantGate, TenantUpdateProvider } from 'services/tenant';
import storeConfig from 'store';
import { ThemeProvider } from 'theme';

import Pages from 'pages/Pages';

import { DefaultSeo } from 'components/@seo';

// We lazy load this chunk because it's only needed for native platforms (reduces bundle size for web)
const EventListenerProvider = lazy(
  () => import('./services/event-listeners/EventListenerProvider'),
);

/**
 * This is the entry point of our app.
 * The following components prevent the rest of app from rendering until they are done:
 * - TenantGate: fetches the tenant config and initializes the services
 * - IdentityGate: initializes the identity service
 * - PrefetchGate: prefetches data that is needed before rendering the rest of the app
 *
 */

const App = () => {
  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
      <TenantGate>
        <HelmetProvider>
          <DefaultSeo />
          <ReduxProvider store={storeConfig.store}>
            <PersistGate persistor={storeConfig.persistor} onBeforeLift={storeConfig.initApp}>
              <ThemeProvider>
                <CssBaseline />
                <IntlProvider>
                  <IdentityGate>
                    <SnackbarProvider>
                      <LazyMotion>
                        <PrefetchGate>
                          <MeasurementsProvider>
                            <TenantUpdateProvider>
                              <ChatClientProvider>
                                {Capacitor.isNativePlatform() ? (
                                  <Suspense>
                                    <EventListenerProvider>
                                      <Pages />
                                    </EventListenerProvider>
                                  </Suspense>
                                ) : (
                                  <Pages />
                                )}
                              </ChatClientProvider>
                            </TenantUpdateProvider>
                          </MeasurementsProvider>
                        </PrefetchGate>
                      </LazyMotion>
                    </SnackbarProvider>
                  </IdentityGate>
                </IntlProvider>
              </ThemeProvider>
              <ReactQueryDevtools position="bottom-right" />
            </PersistGate>
          </ReduxProvider>
        </HelmetProvider>
      </TenantGate>
    </PersistQueryClientProvider>
  );
};

export default App;
