import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import {
  PersistQueryClientProviderProps,
  removeOldestQuery,
} from '@tanstack/react-query-persist-client';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import config from 'config';

import { expectedError } from 'utils/browser.utils';

import { Duration } from 'queries/constants';
import Notification from 'services/snackbar/Notification';
import { NotificationTypes } from 'types/snackbar.types';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: Duration.DAY,
      staleTime: Duration.FIFTEEN_MIN,
      retry: (count, error) => {
        if (count >= 2) return false;
        if (error instanceof AxiosError && expectedError(error)) return false;
        return true;
      },
      meta: {
        // By default we don't want to persist queries to localStorage

        // Enabling this can be useful when the API caches the response for x minutes.
        // After a POST/PUT the API would return the old response for x minutes, then this flag can become handy.
        store: false,
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (error instanceof AxiosError && expectedError(error)) return;

      const errorMessage = query?.meta?.errorMessage;
      if (errorMessage) {
        enqueueSnackbar(
          <Notification
            type={NotificationTypes.Error}
            message={errorMessage}
            notificationKey={errorMessage}
          />,
          { key: errorMessage },
        );
      }
    },
  }),
});

export const localStoragePersister = createSyncStoragePersister({
  key: config.REACT_QUERY_LOCAL_STORAGE_ID,
  storage: window.localStorage,
  retry: removeOldestQuery,
});

export const persistOptions: PersistQueryClientProviderProps['persistOptions'] = {
  buster: `${config.BUILD_ENV}@${config.VERSION}`,
  persister: localStoragePersister,
  // Keep persisted cache for 1 week
  maxAge: Duration.WEEK,
  dehydrateOptions: {
    dehydrateMutations: false,
    shouldDehydrateQuery: (query) => !!query.meta?.store,
  },
  // make sure restored queries are persisted again
  hydrateOptions: { defaultOptions: { queries: { meta: { store: true } } } },
};
