import { useMsal } from '@azure/msal-react';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { useMutation } from '@tanstack/react-query';

import { localStoragePersister } from 'services/react-query';
import storeConfig from 'store';

export const useLogOut = () => {
  const { instance: msalInstance } = useMsal();
  const isMobile = Capacitor.getPlatform() !== 'web';

  const { mutateAsync, isLoading, isError, isSuccess } = useMutation({
    mutationFn: async () => {
      if (isMobile) {
        await msalInstance.logoutRedirect({
          onRedirectNavigate: (url) => {
            Browser.open({ url });
            return false;
          },
        });
      } else {
        await msalInstance.logoutRedirect();
      }
    },
    onMutate: async () => {
      localStorage.clear();
      sessionStorage.clear();

      await storeConfig.persistor.purge();
      await localStoragePersister.removeClient();
    },
  });

  return { logOut: mutateAsync, isLoading, isError, isSuccess };
};
