import { alpha, Box, SxProps, Theme, useTheme } from '@mui/material';

import { useUnreadMessagesCount } from 'services/chat/hooks/useUnreadMessagesCount';

const UnreadMessageCounter = ({
  isActive,
  styles,
}: {
  isActive: boolean;
  styles?: SxProps<Theme> | undefined;
}) => {
  const theme = useTheme();
  const { unreadMessagesCount } = useUnreadMessagesCount();
  if (!unreadMessagesCount) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        borderRadius: '50%',
        backgroundColor: isActive
          ? theme.palette.brand.navigation.main[100]
          : alpha(theme.palette.brand.navigation.main.invert, 0.2),
        color: theme.palette.neutral01[100],
        fontSize: 11,
        fontWeight: 600,
        ...styles,
      }}
    >
      {unreadMessagesCount}
    </Box>
  );
};

export default UnreadMessageCounter;
