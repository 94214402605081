import { NonIndexRouteObject, RouteObject } from 'react-router-dom';

import { useEnabledModules, useMe } from 'queries';
import useChatModuleEnabled from 'services/chat/hooks/useChatModuleEnabled';

import { Modules } from 'components/@navigations/config';

export const useIsModuleEnabled = (moduleId: Modules) => {
  const { data: enabledModules } = useEnabledModules();
  const { data: meData } = useMe();

  return enabledModules?.includes(moduleId) && !meData?.restrictedModules?.includes(moduleId);
};

export const useEnabledRouteModules = (children: Array<RouteObject>) => {
  const { data: enabledModules, isLoading: isLoadingEnabledModules } = useEnabledModules();
  const { data: meData, isLoading: isLoadingMe } = useMe();
  const { isChatEnabled } = useChatModuleEnabled();

  const enabledRoutes = children.filter((route) => {
    const moduleId = route?.moduleId;

    // INFO: Check the chat module in Tenant Settings
    if (route.moduleId === Modules.Chat) {
      return isChatEnabled;
    }
    if (route.isCoreModule) {
      return true;
    } else if (moduleId) {
      return enabledModules?.includes(moduleId) && !meData?.restrictedModules?.includes(moduleId);
    }
    return true;
  });

  const enabledRoutesWithChildren = enabledRoutes.map((route) => {
    return {
      ...route,
      children: route.children?.filter((child) => {
        const moduleId = child?.moduleId;
        if (moduleId) {
          return (
            enabledModules?.includes(moduleId) && !meData?.restrictedModules?.includes(moduleId)
          );
        }
        return true;
      }),
    } as NonIndexRouteObject & { children: Array<RouteObject> };
  });

  return {
    enabledRouteModules: enabledRoutesWithChildren,
    isLoading: isLoadingEnabledModules || isLoadingMe,
  };
};
