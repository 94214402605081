import { alpha, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { cloneDeep, get, PropertyPath, set as _set } from 'lodash';
import { useState } from 'react';

import config from 'config';

import defaultTheme from './theme';

interface Props {
  children: React.ReactNode;
}

const setColor = <T extends object>(
  obj: T,
  key: PropertyPath,
  value: string | undefined,
  light?: boolean,
): T => {
  // if passing a falsey value, revert to the original value
  let finalValue = value;
  if (!value) {
    finalValue = get(obj, key);
  } else if (light) {
    finalValue = alpha(value, 0.1);
  }

  return _set(obj, key, finalValue);
};

const createTheme = () => {
  const theme = cloneDeep(defaultTheme);

  // main
  setColor(theme, 'palette.primary.main', config.THEME.main?.[100]);
  setColor(theme, 'palette.primary.light', config.THEME.main?.[100], true);

  // navigation
  setColor(theme, 'palette.primary.main', config.THEME.main?.[100]);
  setColor(theme, 'palette.primary.light', config.THEME.main?.[100], true);

  // statusses
  setColor(theme, 'palette.error.main', config.THEME.status?.error?.[100]);
  setColor(theme, 'palette.error.light', config.THEME.status?.error?.[100], true);
  setColor(theme, 'palette.error.contrastText', config.THEME.status?.error?.invert);
  setColor(theme, 'palette.success.main', config.THEME.status?.success?.[100]);
  setColor(theme, 'palette.success.light', config.THEME.status?.success?.[100], true);
  setColor(theme, 'palette.success.contrastText', config.THEME.status?.success?.invert);
  setColor(theme, 'palette.warning.main', config.THEME.status?.warning?.[100]);
  setColor(theme, 'palette.warning.light', config.THEME.status?.warning?.[100], true);
  setColor(theme, 'palette.warning.contrastText', config.THEME.status?.warning?.invert);

  // brand
  setColor(theme, 'palette.brand.main.100', config.THEME.main?.[100]);
  setColor(theme, 'palette.brand.main.10', config.THEME.main?.[100], true);
  setColor(theme, 'palette.brand.main.invert', config.THEME.main?.invert);
  setColor(theme, 'palette.brand.greeting', config.THEME.greeting);

  // brand - action
  setColor(theme, 'palette.brand.action.main.100', config.THEME.action?.main?.[100]);
  setColor(theme, 'palette.brand.action.main.invert', config.THEME.action?.main?.invert);
  setColor(theme, 'palette.brand.action.contrast.100', config.THEME.action?.contrast?.[100]);
  setColor(theme, 'palette.brand.action.contrast.invert', config.THEME.action?.contrast?.invert);

  // brand - navigation
  setColor(theme, 'palette.brand.navigation.main.100', config.THEME.navigation?.main?.[100]);
  setColor(theme, 'palette.brand.navigation.main.invert', config.THEME.navigation?.main?.invert);

  // brand - quote
  setColor(theme, 'palette.brand.quote.article.icon', config.THEME.quote?.article?.icon, true);
  setColor(theme, 'palette.brand.quote.article.text', config.THEME.quote?.article?.text);

  // brand - title
  setColor(theme, 'palette.brand.title.news.100', config.THEME.title?.news?.[100]);
  setColor(theme, 'palette.brand.title.forms.100', config.THEME.title?.forms?.[100]);
  setColor(theme, 'palette.brand.title.forms.invert', config.THEME.title?.forms?.invert);
  setColor(theme, 'palette.brand.title.onboarding.100', config.THEME.title?.onboarding?.[100]);
  setColor(theme, 'palette.brand.title.onboarding.invert', config.THEME.title?.onboarding?.invert);

  // brand - poll card
  setColor(theme, 'palette.brand.pollCard.overview.100', config.THEME.pollCard?.overview?.[100]);
  setColor(
    theme,
    'palette.brand.pollCard.overview.invert',
    config.THEME.pollCard?.overview?.invert,
  );
  setColor(
    theme,
    'palette.brand.pollCard.overview.chip.100',
    config.THEME.pollCard?.overview?.chip?.[100],
  );
  setColor(
    theme,
    'palette.brand.pollCard.overview.chip.10',
    config.THEME.pollCard?.overview?.chip?.[100],
    true,
  );
  setColor(
    theme,
    'palette.brand.pollCard.overview.chip.invert',
    config.THEME.pollCard?.overview?.chip?.invert,
  );
  setColor(
    theme,
    'palette.brand.pollCard.overview.result.100',
    config.THEME.pollCard?.overview?.result?.[100],
  );
  setColor(
    theme,
    'palette.brand.pollCard.overview.result.invert',
    config.THEME.pollCard?.overview?.result?.invert,
  );

  // brand - poll title
  setColor(theme, 'palette.brand.pollTitle.detail.100', config.THEME.pollTitle?.detail?.[100]);
  setColor(theme, 'palette.brand.pollTitle.detail.invert', config.THEME.pollTitle?.detail?.invert);

  // brand - site card
  setColor(theme, 'palette.brand.siteCard.detail.100', config.THEME.siteCard?.detail?.[100]);
  setColor(theme, 'palette.brand.siteCard.detail.invert', config.THEME.siteCard?.detail?.invert);

  // brand - topic chip
  setColor(theme, 'palette.brand.topicChip.image.100', config.THEME.topicChip?.image?.[100]);
  setColor(theme, 'palette.brand.topicChip.image.invert', config.THEME.topicChip?.image?.invert);
  setColor(
    theme,
    'palette.brand.topicChip.selection.100',
    config.THEME.topicChip?.selection?.[100],
  );
  setColor(
    theme,
    'palette.brand.topicChip.selection.10',
    config.THEME.topicChip?.selection?.[100],
    true,
  );
  setColor(
    theme,
    'palette.brand.topicChip.selection.invert',
    config.THEME.topicChip?.selection?.invert,
  );
  setColor(
    theme,
    'palette.brand.topicChip.defaultCard.100',
    config.THEME.topicChip?.defaultCard?.[100],
  );
  setColor(
    theme,
    'palette.brand.topicChip.defaultCard.10',
    config.THEME.topicChip?.defaultCard?.[100],
    true,
  );
  setColor(
    theme,
    'palette.brand.topicChip.defaultCard.invert',
    config.THEME.topicChip?.defaultCard?.invert,
  );
  setColor(theme, 'palette.brand.topicChip.news.100', config.THEME.topicChip?.news?.[100]);
  setColor(theme, 'palette.brand.topicChip.news.invert', config.THEME.topicChip?.news?.invert);
  setColor(
    theme,
    'palette.brand.topicChip.textHover.100',
    config.THEME.topicChip?.textHover?.[100],
  );
  setColor(
    theme,
    'palette.brand.topicChip.textHover.invert',
    config.THEME.topicChip?.textHover?.invert,
  );
  setColor(
    theme,
    'palette.brand.topicChip.selectionBlock.100',
    config.THEME.topicChip?.selectionBlock?.[100],
  );
  setColor(
    theme,
    'palette.brand.topicChip.selectionBlock.invert',
    config.THEME.topicChip?.selectionBlock?.invert,
  );

  // brand - poll result
  setColor(
    theme,
    'palette.brand.pollResult.overview.100',
    config.THEME.pollResult?.overview?.[100],
  );
  setColor(
    theme,
    'palette.brand.pollResult.overview.invert',
    config.THEME.pollResult?.overview?.invert,
  );

  // brand - survey card
  setColor(
    theme,
    'palette.brand.surveyCard.overview.100',
    config.THEME.surveyCard?.overview?.[100],
  );
  setColor(
    theme,
    'palette.brand.surveyCard.overview.invert',
    config.THEME.surveyCard?.overview?.invert,
  );
  setColor(
    theme,
    'palette.brand.surveyCard.overview.chip.100',
    config.THEME.surveyCard?.overview?.chip?.[100],
  );
  setColor(
    theme,
    'palette.brand.surveyCard.overview.chip.10',
    config.THEME.surveyCard?.overview?.chip?.[100],
    true,
  );
  setColor(
    theme,
    'palette.brand.surveyCard.overview.chip.invert',
    config.THEME.surveyCard?.overview?.chip?.invert,
  );
  // brand - article card
  setColor(
    theme,
    'palette.brand.articleCard.highlighted.100',
    config.THEME.articleCard?.highlighted?.[100],
  );
  setColor(
    theme,
    'palette.brand.articleCard.highlighted.invert',
    config.THEME.articleCard?.highlighted?.invert,
  );
  setColor(
    theme,
    'palette.brand.articleCard.highlighted.action',
    config.THEME.articleCard?.highlighted?.action,
  );
  setColor(
    theme,
    'palette.brand.articleCard.highlighted.topic.100',
    config.THEME.articleCard?.highlighted?.topic?.[100],
  );
  setColor(
    theme,
    'palette.brand.articleCard.highlighted.topic.invert',
    config.THEME.articleCard?.highlighted?.topic?.invert,
  );
  setColor(
    theme,
    'palette.brand.articleCard.important.100',
    config.THEME.articleCard?.important?.[100],
  );
  setColor(
    theme,
    'palette.brand.articleCard.important.invert',
    config.THEME.articleCard?.important?.invert,
  );

  // brand - profile card
  setColor(theme, 'palette.brand.profileCard.detail.100', config.THEME.profileCard?.detail?.[100]);
  setColor(
    theme,
    'palette.brand.profileCard.detail.invert',
    config.THEME.profileCard?.detail?.invert,
  );

  // brand - survey title
  setColor(theme, 'palette.brand.surveyTitle.detail.100', config.THEME.surveyTitle?.detail?.[100]);
  setColor(
    theme,
    'palette.brand.surveyTitle.detail.invert',
    config.THEME.surveyTitle?.detail?.invert,
  );

  return theme;
};

// this Provider can also be wrapped around the <Splash> component in TenantSetup if the splash screen needs to use theme colors on its exit animation
const ThemeProvider = ({ children }: Props) => {
  const [theme] = useState(createTheme);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
