// Since nothing is loaded at this point the fallback can only return basic HTML & CSS

import { isDev } from 'utils/env.utils';

import { FallbackProps } from 'components/@boundaries/boundary.types';

// Do not attempt to translate this error or add Mui styles, they are not loaded yet!
const RootFallback = ({ error, componentStack }: FallbackProps) => {
  const message = error?.message;

  if (isDev) {
    return (
      <>
        <h1>Whoopsie</h1>
        {message && <p>{message}</p>}
        <pre>{componentStack}</pre>
      </>
    );
  }

  return (
    <div style={{ height: '100vh', display: 'flex' }}>
      <div
        style={{
          width: '1152px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          fontFamily: 'sans-serif',
          backgroundColor: '#F2F7FA',
          paddingTop: 80,
          paddingBottom: 80,
          borderRadius: 16,
          alignSelf: 'center',
          margin: 'auto',
        }}
      >
        {/* refresh icon, can't use Mui here because we're outside of the Provider's scope */}
        <svg fill="#EE2E5D" viewBox="0 0 24 24" width={40} height={40}>
          <path
            fill="#EE2E5D"
            fillRule="evenodd"
            d="M1 12 12 1l11 11-11 11L1 12Zm10-6h2v8h-2V6Zm2 9h-2v2h2v-2Z"
            clipRule="evenodd"
          />
        </svg>

        <h2 style={{ fontSize: '31.25px', marginBottom: 8 }}>Something went wrong</h2>
        <p style={{ fontSize: '16px', margin: 0 }}>Try again later.</p>

        <style
          dangerouslySetInnerHTML={{
            __html: `
            button:hover { filter: brightness(1.1) }
            button:active { filter: brightness(1.2) }
          `,
          }}
        />
      </div>
    </div>
  );
};

export default RootFallback;
