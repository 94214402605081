import { Haptics, ImpactStyle } from '@capacitor/haptics';

const useHaptics = () => {
  const hapticsImpactHeavy = async () => {
    await Haptics.impact({ style: ImpactStyle.Heavy });
  };

  const hapticsImpactMedium = async () => {
    await Haptics.impact({ style: ImpactStyle.Medium });
  };

  const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
  };

  const hapticsVibrate = async () => {
    await Haptics.vibrate();
  };

  const hapticsSelectionStart = async () => {
    await Haptics.selectionStart();
  };

  const hapticsSelectionChanged = async () => {
    await Haptics.selectionChanged();
  };

  const hapticsSelectionEnd = async () => {
    await Haptics.selectionEnd();
  };

  return {
    hapticsImpactHeavy,
    hapticsImpactMedium,
    hapticsImpactLight,
    hapticsVibrate,
    hapticsSelectionStart,
    hapticsSelectionChanged,
    hapticsSelectionEnd,
  };
};

export default useHaptics;
