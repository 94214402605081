import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import config from 'config';

interface Props {
  onHandleRetry: () => void;
}

const IdentityRetry = ({ onHandleRetry }: Props) => {
  const logo = config.ASSETS.logo.onboarding;

  const handleTryAgain = () => {
    onHandleRetry();
  };

  return (
    <Box
      bgcolor="brand.main.100"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      px={2}
    >
      <img src={logo} alt="logo" width="100%" style={{ margin: 'auto' }} />
      <Box alignSelf="flex-end">
        <Box mb={3}>
          <Typography variant="h3" textAlign="center" color="brand.main.invert">
            <FormattedMessage
              id="module_mobile_onboarding_title"
              values={{ tenant_name: config.NAME }}
            />
          </Typography>
          <Typography variant="body2" textAlign="center" color="brand.main.invert">
            <FormattedMessage id="module_mobile_onboarding_description" />
          </Typography>
        </Box>
        <Button fullWidth variant="contrast" size="large" onClick={handleTryAgain} sx={{ my: 3 }}>
          <FormattedMessage id="core_welcome_get_started" />
        </Button>
      </Box>
    </Box>
  );
};

export default IdentityRetry;
