import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { User, UserUpdateParams } from 'types/user.types';

interface Params {
  userId: User['spencerUuid'];
  data: UserUpdateParams;
}

const putUser = async ({ userId, data }: Params) => apiService.putUser(userId, data);

export const useUserUpdate = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: putUser,
    onMutate: async ({ userId, data }) => {
      const queryKey = QueryKeys.users.byId(userId);
      await queryClient.cancelQueries({ queryKey });

      const previousUser = queryClient.getQueryData<User>(queryKey);

      queryClient.setQueryData(queryKey, { ...previousUser, ...data }, { updatedAt: Date.now() });
    },
  });

  return { updateUser: mutate, isLoading };
};
