import { noop } from 'lodash';
import { createContext } from 'react';

const NavbarContext = createContext<{
  header: React.ReactNode;
  setHeader: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  footer: React.ReactNode;
  setFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}>({ header: <></>, setHeader: noop, footer: <></>, setFooter: noop });

export default NavbarContext;
